<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Заболевания (состояния) для оценки" />
      <v-data-table
        :items="preparedNosologies"
        :headers="headers"
        hide-default-footer
        disable-pagination
        @click:row="onRowClick"
        no-data-text="Ничего нет"
      >
        <template v-slot:[`item.isCompleted`]="{ item }">
          <div v-if="item.isCompleted">Да</div>
          <div v-else>Нет</div>
        </template>
      </v-data-table>
    </div>
    <div v-else>Не удалось загрузить заболевания (состояния) для оценки</div>
  </DisplayLoading>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'

import { loadPageData } from '@/mixins/loadPageData'

import { REGION, USER_PR_NOS } from '@/store/const/path'
import { API_PROJECTS_NOSOLOGIES } from '@/store/const/apiPaths'

export default {
  components: {
    PageHeader,
    DisplayLoading,
  },
  mixins: [loadPageData],
  data() {
    return {
      loadUrl: API_PROJECTS_NOSOLOGIES,
      errorMessage: `Не удалось загрузить список заболеваний (состояний)`,
      loadingMessage: 'Загружаю список заболеваний (состояний)',
      headers: [
        { value: 'name', text: 'Заболевания (состояния)' },
        { value: 'isCompleted', text: 'Завершена' },
      ],
    }
  },
  computed: {
    nosologies() {
      return this.pageData
    },
    preparedNosologies() {
      return this.nosologies.map(n => ({
        name: n.nosology.name,
        upnId: n.userProjectNosologies[0].id,
        isCompleted: n.userProjectNosologies[0].is_completed,
      }))
    },
  },
  methods: {
    onRowClick(item) {
      this.$router.push(`/${REGION}/${USER_PR_NOS}-${item.upnId}`)
    },
  },
}
</script>

<style scoped>
.v-data-table {
  cursor: pointer;
}
</style>
